import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { t } from "../utils/constant"
import { withTrans } from "../i18n/withTrans"

const AboutPage = ({ t: trans, intl }) => (
  <Layout>
    <SEO title={t({ intl: trans, id: "seoAbout" })} />

    <div className={"container"}>
      <div className={"targets"}>
        <div className={"container"}>
          <div className={"features"}>
            <div className={"feature__item"}>
              <div className={"column"}>
                <div className={"col-12 feature__center"}>
                  <div className={"feature__content"}>
                    <h2>{t({ intl: trans, id: "headerAboutUs" })}</h2>
                  </div>
                </div>
                <div className={"col-12"}>
                  <div className={"feature__content offerings"}>
                    <p>{t({ intl: trans, id: "textAboutUs" })}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default withTrans(AboutPage)
